<template>
  <validation-observer
    ref="formProfileSettings"
  >
    <b-row>
      <b-col
        sm="6"
        lg="4"
        class="mb-2"
      >
        <validation-provider
          #default="{ errors }"
          name="Perfil"
          rules="required"
        >
          <!-- Perfil -->
          <b-form-group
            label="Perfil*"
            label-for="profiles"
          >
            <v-select
              v-if="getMode === 'insert'"
              id="profiles"
              v-model="defaultProfile"
              variant="custom"
              item-text="description"
              item-value="description"
              label="description"
              :disabled="true"
            />

            <v-select
              v-if="getMode === 'update'"
              id="profiles"
              v-model="getFormData.perfil"
              :options="profiles"
              variant="custom"
              item-text="descricao"
              item-value="id_perfil"
              placeholder="Selecione um perfil"
              label="descricao"
              :disabled="profilesLoading"
              @input="showDataByProfile()"
            >
              <template
                v-slot:no-options
              >
                Nenhum registro encontrado.
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Departamento -->
      <b-col
        v-if="showDepartmentsSelect"
        sm="6"
        lg="4"
        class="mb-2"
      >
        <validation-provider
          #default="{ errors }"
          name="Departamento regional"
          :rules="defineRequiredDepartment"
        >
          <b-form-group
            label="Departamento regional*"
            label-for="departments"
          >
            <v-select
              id="departments"
              v-model="getFormData.departamento"
              :options="departments"
              variant="custom"
              item-text="descricao"
              item-value="id_departamento"
              placeholder="Selecione um departamento"
              label="descricao"
              :disabled="departmentsDisable"
              @input="showDataByDepartment()"
            >
              <template
                v-slot:no-options
              >
                Nenhum registro encontrado.
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Regiões -->
      <b-col
        v-if="showRegionsSelect"
        sm="6"
        lg="4"
        class="mb-2"
      >
        <b-form-group
          label="Regiões relacionadas*"
          label-for="regions"
        >
          <v-select
            id="regions"
            v-model="getFormData.regioes"
            :options="regions"
            variant="custom"
            item-text="descricao"
            item-value="id_regiao"
            :placeholder="definePlaceholderRegionsSelect"
            label="descricao"
            :disabled="regionsLoading"
            :multiple="defineMultiplesRegions"
            @input="showDataByRegions()"
          >
            <template
              v-slot:no-options
            >
              Nenhum registro encontrado.
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- Unidades -->
      <b-col
        v-if="showUnitsSelect"
        sm="6"
        lg="4"
        class="mb-2"
      >
        <b-form-group
          label="Unidade*"
          label-for="units"
        >
          <v-select
            id="units"
            v-model="chosenUnit"
            :options="units"
            variant="custom"
            item-text="descricao"
            item-value="id_unidade"
            placeholder="Selecione uma unidade"
            label="descricao"
            @input="chooseUnit()"
          >
            <template
              v-slot:no-options
            >
              Nenhum registro encontrado.
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- Unidades Checkbox -->
      <b-col
        v-if="showManyUnitsCheck"
        cols="12"
        class="mb-2"
      >
        <div class="d-flex flex-wrap">
          <div
            v-for="(region, i) in getFormData.regioes"
            :key="i"
            class="card-units"
          >
            <b-form-group
              label-class="font_size_label"
              :label="region.descricao"
              class="d-flex"
            >
              <small v-if="region.unidade.length === 0">
                Nenhum registro encontrado.
              </small>

              <b-form-checkbox-group
                id="checkbox-units"
                v-model="getFormData.unidades"
                name="checkbox-units"
              >
                <b-form-checkbox
                  v-for="(unit, j) in region.unidade"
                  :key="j"
                  :value="unit.id_unidade"
                >
                  {{ unit.descricao }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  getDepartments,
  getProfiles,
  getRegions,
} from '@/views/custom-pages/gerenciarUsuarios/requests'
import chavePerfil from '@/enum/chavePerfil'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormCheckboxGroup,
    BFormCheckbox,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
  },
  props: {
    className: {
      type: String,
      default: '',
    },

    mode: {
      type: String,
      default: 'insert',
    },
  },

  data() {
    return {
      profilesLoading: true,
      departmentsDisable: true,
      regionsLoading: true,
      unitsLoading: true,

      defaultProfile: {
        key: 'ADMIN_EMPRESA',
        description: 'Admin Empresa',
      },

      showDepartmentsSelect: false,
      showRegionsSelect: false,
      showUnitsSelect: false,
      showManyUnitsCheck: false,

      profiles: [],

      departments: [],
      regions: [],
      units: [],

      chosenUnit: null,
    }
  },

  computed: {
    getMode() {
      return this.mode
    },

    getFormData() {
      return this.$store.getters['companyUserState/getFormData']
    },

    getDepartmentsInFormData() {
      return this.getFormData.departamento
    },

    getRegionsInFormData() {
      return this.getFormData.regioes
    },

    getProfileKey() {
      return this.getFormData.perfil ? this.getFormData.perfil.chave : null
    },

    defineMultiplesRegions() {
      return ![chavePerfil.ADMIN_UNIDADE].includes(this.getProfileKey)
    },

    definePlaceholderRegionsSelect() {
      if (this.defineMultiplesRegions) {
        return 'Selecione uma ou mais'
      }

      return 'Selecione uma região'
    },

    getDnProfileMode() {
      return [
        chavePerfil.ADMIN_DN,
        chavePerfil.SAUDE_DN,
        chavePerfil.MERCADO_DN,
      ].includes(this.getProfileKey)
    },

    getDnDrProfileMode() {
      return [
        chavePerfil.ADMIN_DN,
        chavePerfil.SAUDE_DN,
        chavePerfil.ADMIN_DR,
        chavePerfil.SAUDE_DR,
        chavePerfil.MERCADO_DR,
        chavePerfil.SAUDE_REGIAO,
        chavePerfil.ADMIN_UNIDADE,
        chavePerfil.VACINADOR_PADRAO,
        chavePerfil.VACINADOR_ADMIN,
        chavePerfil.SAUDE_UO,
      ].includes(this.getProfileKey)
    },

    getUoProfileMode() {
      return [
        chavePerfil.SAUDE_REGIAO,
        chavePerfil.ADMIN_UNIDADE,
        chavePerfil.VACINADOR_PADRAO,
        chavePerfil.VACINADOR_ADMIN,
        chavePerfil.SAUDE_UO,
      ].includes(this.getProfileKey)
    },

    defineRequiredDepartment() {
      return this.getProfileKey !== chavePerfil.ADMIN_EMPRESA ? 'required' : ''
    },
  },

  watch: {
    getDepartmentsInFormData(value) {
      if (!value) {
        this.showRegionsSelect = false
        this.showUnitsSelect = false

        this.getFormData.regioes = []
        this.getFormData.unidades = []
      }
    },

    getRegionsInFormData(value) {
      if (!value) {
        this.showUnitsSelect = false

        this.getFormData.unidades = []
      }
    },
  },

  mounted() {
    if (this.getMode === 'update') {
      this.handleGetProfiles()
      this.handleGetDepartments()
    }
  },

  methods: {
    showDataByProfile() {
      this.getFormData.departamento = null

      if (this.getDnDrProfileMode) {
        this.showDepartmentsSelect = true
      } else {
        this.showDepartmentsSelect = false

        this.getFormData.perfil = this.profiles.filter(item => item.chave === chavePerfil.ADMIN_EMPRESA)
        this.getFormData.departamento = null
        this.getFormData.regioes = []
        this.getFormData.unidades = []
      }

      if (this.getDnProfileMode) {
        this.departmentsDisable = true

        this.getFormData.departamento = {
          id_departamento: 1,
          descricao: 'Departamento Nacional',
        }
      }
    },

    showDataByDepartment() {
      if (this.getUoProfileMode) {
        this.showRegionsSelect = true

        this.handleGetRegions()
      } else {
        this.showRegionsSelect = false

        this.getFormData.regioes = []
        this.getFormData.unidades = []
      }
    },

    showDataByRegions() {
      if (this.getProfileKey === chavePerfil.ADMIN_UNIDADE) {
        this.showUnitsSelect = true

        this.units = this.getFormData.regioes ? this.getFormData.regioes.unidade : []
      } else {
        this.showUnitsSelect = false
      }

      this.showManyUnitsCheck = (this.getProfileKey !== chavePerfil.ADMIN_UNIDADE) && this.getUoProfileMode
    },

    async handleGetProfiles() {
      this.profilesLoading = true

      await getProfiles()
        .then(response => {
          const { data } = response

          this.profiles = data
        })

      this.profilesLoading = false
    },

    async handleGetDepartments() {
      this.departmentsDisable = true

      await getDepartments()
        .then(response => {
          const { data } = response

          this.departments = data
        })

      this.departmentsDisable = false
    },

    async handleGetRegions() {
      this.regionsLoading = true

      if (this.getFormData.departamento) {
        const params = { id_departamento: this.getFormData.departamento.id_departamento }

        await getRegions(params)
          .then(response => {
            const { data } = response

            this.regions = data
          })
      }

      this.regionsLoading = false
    },

    chooseUnit() {
      this.getFormData.unidades = this.chosenUnit ? [this.chosenUnit.id_unidade] : []
    },
  },
}
</script>

<style scoped lang="scss">
.card-units {
  width: 200px;
  background-color: #e5f0f9;
  padding: 1rem;
  margin-bottom: 1.15rem;
  margin-right: 1rem;
  border-radius: 0.357rem;
}

@media(width < 300px) {
  .card-units {
    width: auto;
    margin-right: 0;
  }
}
</style>
