<template>
  <div class="content-wrapper">
    <page-header
      screen-name="Cadastrar Novo"
      :link-items="linkItems"
    />

    <Form mode="insert" />
  </div>
</template>

<script>

import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import Form from './Form.vue'

export default {
  components: {
    PageHeader,
    Form,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Gerenciar usuários',
          routeName: 'usuario-empresa-list',
        },
        {
          name: 'Cadastrar Novo',
          active: true,
        },
      ],
    }
  },

  mounted() {
    this.$store.commit('companyUserState/clearFormData')
  },
}
</script>
